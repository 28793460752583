<template>
    <div class="col-md-12">
      <div class="card card-container">
        <img
          id="profile-img"
          src="//seeklogo.com/images/G/globe-logo-42DE548AC7-seeklogo.com.png"
          class="profile-img-card"
        />
        <Form @submit="submitForm" :validation-schema="schema">
          <div class="form-group">
            <label for="ip">IP</label>
            <Field name="ip" type="text" class="form-control" v-model="form.ip" />
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="country">Country</label>
            <Field name="country" type="text" class="form-control" v-model="form.country" />
            <ErrorMessage name="country" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="region">Region</label>
            <Field name="region" type="text" class="form-control" v-model="form.region" />
            <ErrorMessage name="region" class="error-feedback" />
          </div>
          <div class="form-group">
              <label for="city">City</label>
              <Field name="city" type="text" class="form-control" v-model="form.city" />
              <ErrorMessage name="city" class="error-feedback" />
          </div>
          <div class="form-group">
              <label for="postal_code">Postal code</label>
              <Field name="postal_code" type="text" class="form-control" v-model="form.postal_code" />
              <ErrorMessage name="postal_code" class="error-feedback" />
          </div>

          <div>
            <br>
            </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block form-control" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Send</span>
            </button>
          </div>
            <div>
                <br>
            </div>
          <div class="form-group">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
          </div>
          </div>
          <div class="form-group">
            <div v-if="messageSuccess" class="alert alert-success" role="alert">
              {{ messageSuccess }}
          </div>
          </div>
        </Form>
      </div>
    </div>
  </template>                                                                
                                                                               
<script>
import api from '../api';
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {                                                             
  name: 'ipAdd',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {  
    const schema = yup.object().shape({
      ip: yup.string().required("IP is required!"),
      country: yup.string().required("Country is required!"),
      region: yup.string().required("Region is required!"),
      city: yup.string().required("City is required!"),
      postal_code: yup.string().required("Postal code is required!"),
      //vlan: yup.string().required("VLAN is required!"),
      //mac: yup.string().required("MAC is required!"),
    });
    return {   
        loading: false,                                                              
        form: {                                                                
          ip: '',                                                            
          country: '',
          region: null,
          postal_code: '',
          schema,
        },
        size: 10,
        message: '',
        messageSuccess: '',
    };                                                                       
  },
  async mounted() {
    //this.form.password = this.password_gen();
    //this.form.vlan = this.get_next_vlan();
    this.form.country = 'RS';
    this.form.region = 'RS-00';
    this.form.city = 'Belgrade';
    this.form.postal_code = '11000';
},
  methods: {
    async get_next_vlan() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
      try {
        await api.get('/users/vlan', {
          headers
        })
        .then((response) => this.form.vlan = response.data.next_vlan)
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async submitForm() {                                                           
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        };
        this.loading = true;
        try {
            const post = await api.post('/add', {
                  //name: this.form.name,
                  ip: this.form.ip,
                  country: this.form.country,
                  region: this.form.region,
                  city: this.form.city,
                  postal_code: this.form.postal_code,
              }, {
                headers
            });
            const response = post.data;
            if(response != null && response.message === 'Added to database'){
                this.loading = false;
                this.message = '';
                this.messageSuccess = response.message;
                this.form.ip = '';
                this.form.country = 'RS';
                this.form.region = 'RS-00';
                this.form.city = 'Belgrade';
                this.form.postal_code = '11000';
                this.$router.push('/ip/add');
            } else {
                this.loading = false;
                this.messageSuccess = '';
                throw new Error(response.message);
            }
        } catch (error) {
            this.loading = false;
            this.messageSuccess = '';
            this.message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            console.error('Adding failed:', error);                                                                                                      
      }
    },
  },
};                                                                           
</script>
<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>