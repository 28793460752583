<template>                                                                   
    <div id="app">                                                             
      <AppNavigation />                                                           
      <router-view />                                                          
    </div>                                                                     
  </template>                                                                  
                                                                               
  <script>                                                                     
  import AppNavigation from './components/Navigation.vue';
  import { computed, ref } from 'vue';
  import { useFavicon } from '@vueuse/core';
  
  const type = ref('vue')
  const favicon = computed(() =>
  type.value === 'vue' ? 'favicon.svg' : 'favicon.svg')

  useFavicon(favicon, {
    baseUrl: '/',
    rel: 'icon',
  })
  
  export default {                                                             
    name: 'App',                                                               
    components: {                                                              
      AppNavigation,                                                              
    },                                                                         
  };                                                                           
  </script>                                                                    
                                                                               
  <style>                                                                      
  #app {                                                                       
    max-width: auto;                                                          
    margin: 0 0;                                                            
    padding: 30px;                                                             
  }
  </style>