<template>
    <div class="grid-container">
        <div id="chart">
            <Doughnut id="my-chart-id" :options="chartOptions" v-if="loaded" :data="chartData" />
        </div>
        <div id="divider-vertical"></div>
        <div id="chart1">
            <Bar id="my-chart-id1" :options="chartOptions" v-if="loaded" :data="chartData1" />
        </div>
        <div id="divider-horizontal"></div>
    </div>
</template>
                                                                               
<script>
import { Bar, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
//import { Doughnut } from 'vue-chartjs'
import {
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js'
//import { Line } from 'vue-chartjs'
import api from '../api';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement,)

export default {                                                             
  name: 'CountPage',                                                              
  components: {
    //Doughnut,
    //Line,
    Bar,
    Doughnut
},
  data () {
    return {
      loaded: false,
      chartData: null,
      chartData1: null,
      data: {},
      data1: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
    }
  },
  async mounted() {
    this.api();
  },
  methods: {
    async api () {
      //this.loaded = false
      try {
        const response = await api.get('/stats/count', {                 
             headers: {                       
               'Content-Type': 'application/json',                                    
               'Authorization': `Bearer ${localStorage.getItem('token')}`,                            
             }, 
        });
        const response1 = await api.get('/stats/countries', {                 
             headers: {                       
               'Content-Type': 'application/json',                                    
               'Authorization': `Bearer ${localStorage.getItem('token')}`,                            
             }, 
        });
        if (response) {
            this.data = response.data;
        } else {
            this.data = [1,1];
        }
        if (response1) {
            this.data1 = response1.data;
        } else {
            this.data1 = [0,0];
        }
        this.chartData = {
          labels: this.data1['country'],
          datasets: [
            {
              label: 'Percentage of countries in DB',
              backgroundColor: ['#41B883', '#E46651', '#E46658', '#52F44A'],
              data: this.data1['count']
            }
          ]
        };
        this.chartData1 = {
          labels: ['Count'],
          datasets: [
            {
              label: 'IPs in DB',
              backgroundColor: ['#41B883'],
              //data: [this.data1['online_users'], this.data1['all_users']]
              data: this.data['count']
            }
          ]
        };
        this.loaded = true;
        } catch (e) {
        console.error(e);
        }

  }
},
};
</script>
<style scoped>
#chart {
  width: 40%;
  height: 100%;
  margin: 20 left;
  float: left;
}
#chart1 {
  width: 40%;
  height: 100%;
  margin: right 20;
  float: right;
}
/**
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1px 1fr; /* Two columns with a 1px vertical divider in the middle
  grid-template-rows: 1fr 1px 1fr; /* Two rows with a 1px horizontal divider in the middle
  width: 100%;
  height: 100%; /* Adjust the height as needed */
/*/
/**
#chart, #chart1 {
  width: 100%;
  height: 100%;
  margin: 0 center;
  float: left;
}

#divider-vertical {
  background-color: #000;
  grid-row: 1 / span 3;
  grid-column: 2;
  height: 100%;
}

#divider-horizontal {
  background-color: #000;
  grid-row: 2;
  grid-column: 1 / span 3;
  width: 100%;
}
*/
</style>