<template>
    <div id="search">
      <h1>IP Blocks</h1>
      <vue-good-table
        :columns="columns"
        :rows="filteredUsers"
        :search-options="{ enabled: true, placeholder: 'IP List' }"
        :paginate="true"
        :pagination-options="{ enabled: true, perPage: 10 }"
        :lineNumbers="true"
        @change="changeCell">
        <template #table-row="props">
        <span v-if="props.column.field == 'edit'">
          <button type="button" class="btn btn-warning" v-on:click="edit(props.row.id)">
            <i class="fas fa-edit"></i>
          </button>
        </span>
        <span v-else-if="props.column.field == 'remove'">
          <button type="button" class="btn btn-danger" v-on:click="removeUser(props.row)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </span>
        <span v-else>
          <span v-if="props.column.field && props.row.isEditable">
            <input v-model="props.row[props.column.field]" @change="saveChanges(props.row)"/>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>
    </vue-good-table>
    </div>
</template>
  
<script>
//<input v-model="props.row[props.column.field]" @input="editCell(props.row, props.column.field)" />
import api from '../api';
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

export default {
  name: 'ipList',
  data() {
    return {
      users: [],
      search: '',
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'IP', field: 'ip' },
        { label: 'Country', field: 'country' },
        { label: 'Region', field: 'region' },
        { label: 'City', field: 'city'},
        { label: 'Postal Code', field: 'postal_code' },
        { label: 'Edit', field: 'edit' },
        { label: 'Remove', field: 'remove' }
      ],
      mediaPlanData: [],
      dot: null,
    };
  },
  methods: {
    startEditing(props) {
      // Enable editing for clicked row
      this.$set(props.row, 'editing', true);
      console.log(`Editing user with ID ${props.row.id}`);
      console.log(props.row);
    },
    //editCell(user, field) {
    //  // Save the edited data to your API or perform other actions
    //  // For example, you can make an API call to update the user data
    //  console.log(`Edited ${field} for user ID: ${user.id} to ${user[field]}`);
    //},
    edit(id) {
      const userToEdit = this.users.find(user => user.id === id);
      if (userToEdit) {
        userToEdit.isEditable = true;
        this.dot = userToEdit;
      }
    },
    //changeCell(changedData, column, row) {
    changeCell() {
            console.log('changedData');
            const userToEdit = this.dot;
            if (userToEdit) {
              userToEdit.isEditable = false;
            }
    },
    async saveChanges(user) {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };
      const payload = {
        id: user.id,
        ip: user.ip,
        country: user.country,
        region: user.region,
        city: user.city,
        postal_code: user.postal_code,
      };
      try {
        // Make an API call to update the user data
        await api.put('/update', payload, {headers});
        user.editing = false; // Disable editing after successful API call
        console.log(`IP with ID ${user.id} updated successfully!`);
        //refresh vue good table
        this.refresh();
      } catch (error) {
        console.error('Error updating IP:', error);
        // Handle error scenarios, show error messages, etc.
      }
    },
    async removeUser(user) {
      try {
        const index = user.originalIndex;

        // Make an API call to remove the user
        const response = await api.delete(`/delete`, { headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }, data: { ip: user.ip}});
        const message = response.data.message;
        if (message === 'ok') {
          console.log('success: ' + message);
          //this.$delete(user, 'editing'); // Remove the editing property from the user object
          // Remove the user from the local data
          if (index !== -1) {
            this.users.splice(index, 1);
          }
          console.log(`User with ID ${user.id} removed successfully!`);
        } else {
          //raise error
          throw new Error(message);
        }
      } catch (error) {
        console.error('Error removing IP:', error);
        // Handle error scenarios, show error messages, etc.
      }
    },
    async refresh() {
    // Fetch data from your API endpoint using Axios
    api.get('/list', {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
      }
    })
      .then(response => {
        this.users = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
    },
  computed: {
    filteredUsers() {
      return this.users.filter(user => user.ip.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  components: {
    VueGoodTable,
  },
  async mounted() {
    // Fetch data from your API endpoint using Axios
    api.get('/list', {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
      }
    })
      .then(response => {
        this.users = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style scoped>
#search {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
